import { useCurrentUser } from 'api/auth';
import { PRODUCT_SAM } from 'api/organization';
import { SidebarLink } from './SidebarLink';
import { useAuth } from 'providers/Auth/AuthProvider';

import css from './Sidebar.module.scss';

export const Sidebar = () => {
	const { data: user } = useCurrentUser();
	const { logout } = useAuth();

	const isManager = user?.role === 'manager' || user?.role === 'admin';
	const hasSam = user?.product === PRODUCT_SAM || user?.role === 'admin';
	const hasAudits = (user?.role === 'manager' && user?.has_audits) || user?.role === 'admin';
	const hasExchange =
		(user?.role === 'manager' && user?.has_exchange_report) || user?.role === 'admin';

	const productDisplayName =
		!user?.role || !user?.product || user?.role === 'admin'
			? ''
			: user?.product === PRODUCT_SAM
			? 'SAM '
			: 'JUDI ';

	const inventoryProductSuffix = productDisplayName ? ` (${productDisplayName.trim()})` : '';

	return (
		<nav className={css.sidebar}>
			<div className={css.navSectionLabel}>Overview</div>
			<SidebarLink to='' title={`Dashboard${inventoryProductSuffix}`} icon='Home' exact={true} />
			<SidebarLink to='/inventory' title={`Inventory${inventoryProductSuffix}`} icon='Clipboard' />
			{hasAudits && <SidebarLink to='/link' title='Audits / Counts (LINK)' icon='EditList' />}

			{isManager && (
				<>
					<div className={css.navSectionLabel}>Reporting</div>
					<SidebarLink to='/reporting' title='Inventory Overview Report' icon='Chart' />
					{hasSam && (
						<SidebarLink
							to='/reporting-sam'
							title='Asset Overview Report (SAM)'
							icon='Chart'
						/>
					)}
					{hasExchange && (
						<SidebarLink to='/exchange' title='Exchange Report (XCHG)' icon='Chart' />
					)}
					{hasExchange && (
						<SidebarLink
							to='/saved-reports/exchange'
							title='Saved Exchange Reports'
							icon='Chart'
						/>
					)}
				</>
			)}

			{user?.role === 'admin' && (
				<>
					<div className={css.navSectionLabel}>Admin</div>
					<SidebarLink to='/organizations' title='Manage Organizations' icon='Office' />
				</>
			)}
			<SidebarLink
				to={() => {
					logout();
				}}
				isVisibleOnDesktop={false}
				title='Sign Out'
				icon='Logout'
			/>
		</nav>
	);
};
